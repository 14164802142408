import * as Sentry from "@sentry/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { ThemeWrapper } from "library-ui-components";
import { BrowserAuthWrapper } from "library-frontend-utils/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import config from "../deploy";
import { ErrorPage, LoadingPage } from "library-ui-components/pages";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 1, staleTime: 12 * 60 * 60 * 1000 } },
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      <BrowserAuthWrapper config={config}>
        <QueryClientProvider client={queryClient}>
          <ThemeWrapper>
            <RouterProvider router={router} fallbackElement={<LoadingPage />} />
          </ThemeWrapper>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserAuthWrapper>
    </Sentry.ErrorBoundary>
  );
}

export default App;
